import Typography from 'components/commercetools-ui/atoms/typography';
import useCostsData from 'components/commercetools-ui/organisms/order-payment-section/hooks/useCostsData';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useParams } from 'next/navigation';
import { FC, useRef, useState } from 'react';
import { Order } from 'shared/types/cart/Order';
import { Payment } from 'shared/types/cart/Payment';
import { Money } from 'shared/types/product/Money';
import OrderSummaryList from './order-summary-list';

type OrderSummaryProps = {
  order: Order;
  payment?: Payment;
  // onPrint: () => void;
};

const OrderSummary: FC<OrderSummaryProps> = ({ order, payment }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage } = useFormat({ name: 'thank-you' });
  const { locale } = useParams();
  const { costsToRender } = useCostsData({ dataReference: 'order', order, includeFeeAndTax: true });

  const summaryInfo: Array<{ label: string; value?: Money }> = [
    costsToRender[1], // shipping
    costsToRender[2], // tax
  ];
  const [formattedDate, setFormattedDate] = useState<string>(() => {
    const createDate = new Date(order.createdAt || '');
    return createDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  });

  const paymentInfo = useRef(() => {
    if (payment) {
      return `${payment?.custom?.fields?.ccType}  ****${payment?.custom?.fields?.ccLastFour}`;
    }
    return 'PayPal';
  });

  const streetInfo = useRef(() => {
    const info = [];
    if (order.billingAddress?.city) {
      info.push(order.billingAddress?.city);
    }

    if (order.billingAddress?.state) {
      info.push(order.billingAddress?.state);
    }

    if (order.billingAddress?.postalCode) {
      info.push(order.billingAddress?.postalCode);
    }

    return info.join(', ');
  });

  return (
    <div className="mt-24 grow pb-24 text-14 text-padi-gray-darker md:text-16 lg:pb-36">
      <div className="grid gap-8 border-b border-t border-neutral-400 bg-white py-32">
        <Typography className="font-normal  leading-loose">
          {`${formatMessage({ id: 'orderDate', defaultMessage: 'Order Date' })} ${formattedDate}`}
        </Typography>
      </div>

      <OrderSummaryList order={order} />

      <div className="grid gap-8 border-b border-t border-neutral-400 bg-white py-16 lg:pt-32">
        <Typography className="font-medium text-primary-black md:text-18 lg:leading-loose">
          {formatMessage({ id: 'payment', defaultMessage: 'Payment' })}
        </Typography>
        <Typography className="mt-0 capitalize">{paymentInfo.current()}</Typography>
      </div>

      <div className="grid gap-8 border-b border-t border-neutral-400 bg-white py-16 lg:pt-32">
        <Typography className="font-medium text-primary-black md:text-18 lg:leading-loose">
          {formatMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
        </Typography>
        <Typography className="mt-0">
          {`${order.billingAddress?.firstName} ${order.billingAddress?.lastName}`}
        </Typography>

        <Typography className="mt-0">{order.billingAddress?.streetName}</Typography>
        <Typography className="mt-0">{streetInfo.current()}</Typography>
        <Typography className="mt-0">{order.billingAddress?.country}</Typography>
      </div>

      {/* Order summary info */}
      <div className="grid gap-8 border-t border-neutral-400 bg-white py-16 lg:pt-32">
        <Typography className="font-medium text-primary-black md:text-18 lg:leading-loose">
          {formatMessage({ id: 'order.details', defaultMessage: 'Order Details' })}
        </Typography>
        <div className="mt-16 flex max-w-350 items-center justify-between">
          <Typography>{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</Typography>
          <Typography className="mt-0 text-primary-black">
            {CurrencyHelpers.formatForCurrency(order.taxed?.amount ?? 1, locale)}
          </Typography>
        </div>
        {(order.discountCodes?.length || 0) > 0 && (
          <Typography>{formatMessage({ id: 'promotionApplied', defaultMessage: 'Promotion(s) Applied' })}</Typography>
        )}

        {summaryInfo.map(({ label, value }, index) => {
          return (
            <div key={index} className="mt-16 flex max-w-350 items-center justify-between">
              <Typography>{label}</Typography>
              <Typography className="mt-0 text-primary-black">
                {CurrencyHelpers.formatForCurrency(value ?? 0, locale)}
              </Typography>
            </div>
          );
        })}

        <div className="mt-16 flex max-w-350 items-center justify-between lg:border-t lg:border-neutral-400 lg:pt-12">
          <Typography className="font-medium text-primary-black md:text-18 lg:leading-loose">
            {formatCartMessage({ id: 'total', defaultMessage: 'Total' }) + ':'}
          </Typography>
          <Typography className="mt-0 font-medium text-primary-black md:text-18 lg:leading-loose">
            {CurrencyHelpers.formatForCurrency(order.payments?.[0]?.amountPlanned ?? 999999, locale)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
